import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-订货管理-获取异步供货单的执行状态
 * @param {*} params
 * @returns
 */
export function getOrderTaskStatus(params = {}) {
  return axios.get(`${BASE_URL}/supplier/order/task/status`, {params});
}
