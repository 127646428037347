var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "orderlist bj order-container",
      attrs: { "element-loading-text": "正在生成供货单..." },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "seach order-wrapper" },
          [
            _c("el-button", {
              staticClass: "bjsearch",
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.Refresh },
            }),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "商品名称/货号", clearable: "" },
                  model: {
                    value: _vm.from1.product_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "product_name", $$v)
                    },
                    expression: "from1.product_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入主供货商", clearable: "" },
                  model: {
                    value: _vm.from1.supplier_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "supplier_name", $$v)
                    },
                    expression: "from1.supplier_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入采购员", clearable: "" },
                  model: {
                    value: _vm.from1.buyer_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "buyer_name", $$v)
                    },
                    expression: "from1.buyer_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs multiple-select-long" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      multiple: "",
                      clearable: "",
                      placeholder: "选择城市仓",
                    },
                    on: { change: _vm.cityChange },
                    model: {
                      value: _vm.from1.logistics_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "logistics_id", $$v)
                      },
                      expression: "from1.logistics_id",
                    },
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "el-select-dropdown__item",
                        class: { selected: _vm.serviceSelectedAll },
                        on: { click: _vm.onServiceSelectedAll },
                      },
                      [_c("span", [_vm._v("全选")])]
                    ),
                    _vm._l(_vm.storelist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择集配中心" },
                    model: {
                      value: _vm.from1.logistic_business_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "logistic_business_id", $$v)
                      },
                      expression: "from1.logistic_business_id",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs multiple-select" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      multiple: "",
                      clearable: "",
                      placeholder: "选择自提点",
                    },
                    model: {
                      value: _vm.from1.delivery_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "delivery_id", $$v)
                      },
                      expression: "from1.delivery_id",
                    },
                  },
                  _vm._l(_vm.selflist, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.search },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              { attrs: { type: "success" }, on: { click: _vm.listHandle } },
              [_vm._v("生成供货单")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                  "text-align": "center",
                },
                "tooltip-effect": "dark",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsName", align: "center", label: "主图" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.product
                          ? [
                              _c("el-image", {
                                staticClass: "kv pointer",
                                attrs: {
                                  src: scope.row.product.kv,
                                  "preview-src-list": [scope.row.product.kv],
                                  alt: "",
                                },
                              }),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  align: "left",
                  width: "250",
                  label: "商品信息",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "mb-15",
                                  staticStyle: { "font-weight": "bold" },
                                },
                                [
                                  scope.row.product.title
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.product.title)),
                                      ])
                                    : _vm._e(),
                                  scope.row.product.spec
                                    ? _c("span", [
                                        _vm._v(
                                          "#" + _vm._s(scope.row.product.spec)
                                        ),
                                      ])
                                    : _vm._e(),
                                  scope.row.product.sku_code
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(scope.row.product.sku_code) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", { staticClass: "mb text-light" }, [
                                _vm._v(
                                  " 等级/规格：" +
                                    _vm._s(
                                      _vm.$empty.empty(scope.row.product.grade)
                                    ) +
                                    " "
                                ),
                                scope.row.product.unit_type == 1
                                  ? _c("span", [_vm._v("/件")])
                                  : _vm._e(),
                                scope.row.product.unit_type == 2
                                  ? _c("span", [_vm._v("/箱")])
                                  : _vm._e(),
                                scope.row.product.unit_type == 3
                                  ? _c("span", [_vm._v("/袋子")])
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "mb" }, [
                                _vm._v(
                                  " 毛重/毛单价：" +
                                    _vm._s(
                                      _vm.$empty.empty(
                                        scope.row.product.gross_weight
                                      )
                                    ) +
                                    " 【" +
                                    _vm._s(
                                      _vm.tool.toDecimal2(
                                        (((scope.row.product.price * 1000) /
                                          scope.row.product.gross_weight) *
                                          1000) /
                                          100 /
                                          1000000
                                      )
                                    ) +
                                    "元/斤】 "
                                ),
                              ]),
                              _c("div", { staticClass: "mb-15" }, [
                                _vm._v(
                                  " 净重/净单价：" +
                                    _vm._s(
                                      _vm.$empty.empty(scope.row.product.weight)
                                    ) +
                                    "【" +
                                    _vm._s(
                                      _vm.tool.toDecimal2(
                                        (((scope.row.product.price * 1000) /
                                          scope.row.product.weight) *
                                          1000) /
                                          100 /
                                          1000000
                                      )
                                    ) +
                                    "元/斤】 "
                                ),
                              ]),
                            ])
                          : _c("div", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logistics_business_name",
                  align: "center",
                  label: "集配中心",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.logistics_business_name
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.logistics_business_name
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "supplier_name",
                  align: "center",
                  label: "主供货商",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.supplier_name
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.supplier_name)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logistics_name",
                  align: "center",
                  label: "城市仓",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.logistics_name
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.logistics_name)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_name",
                  align: "center",
                  label: "自提点",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.delivery_name
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.delivery_name)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_qty",
                  align: "center",
                  label: "订货数量",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$empty.empty(scope.row.order_qty))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "buyer_name", align: "center", label: "采购员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$empty.empty(scope.row.buyer_name))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": 10,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }